@font-face {
    font-family: 'GothamPro';
    src: url(../assets/fonts/gothampro-webfont.woff2) format('woff2'),
          url(../assets/fonts/gothampro-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
      font-family: 'GothamPro_Bold';
      src: url(../assets/fonts/gothampro-medium-webfont.woff2) format('woff2'),
           url(../assets/fonts/gothampro-medium-webfont.woff) format('woff');
      font-weight: normal;
      font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url(../assets/fonts/lato-regular-webfont.woff2) format('woff2'),
          url(../assets/fonts/lato-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato_Bold';
    src: url(../assets/fonts/lato-bold-webfont.woff2) format('woff2'),
          url(../assets/fonts/lato-bold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
  }